import './Navigation.scss';

const Navigation = () => {
  
  return (
    <div className='nav-wrapper'>
        <div className='nav-body'>
            <img alt='lookho' src='lookhoLogoThree.svg' />
        </div>
    </div>
  )
}

export default Navigation